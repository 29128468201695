.body4__card {
    box-shadow: 0px 0px 30px 2px rgba(128, 128, 128, 0.281);
    margin: 5px 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 20px;
    background-color: white;
    border-radius: 7px;
    width: 120px;
    text-decoration: none;
    color: rgb(65, 60, 60);
   
}

.body4__card:hover{
    text-decoration: none;
    color: black;
}

.icon__style{
    background-color: green !important;
    padding: 20px !important;
    border-radius: 10px !important;
    margin-left: px !important;
}

.icon__style>span>.MuiSvgIcon-root{
    color: white !important;
}

.shortTitle{
    color: gray;
    font-size: 14px;
}

@media screen and (max-width: 768px) {
    .shortTitle{
        display: none;
    }
    .body4__card {
        width: 100%;
    }
}


